@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~@angular/cdk/overlay-prebuilt.css';
// @import '~ngx-sharebuttons/themes/material/material-dark-theme';
// @import '~ngx-sharebuttons/themes/modern/modern-light-theme';
// @import '~ngx-sharebuttons/themes/circles/circles-dark-theme';
// @import '~@ngx-share/button/styles/share-buttons.css';
// @import '~@ngx-share/button/styles/themes/default/default-theme.css';
// @import '~ngx-sharebuttons/themes/default';
// @import '~ngx-toastr/toastr';
// @import '~@ctrl/ngx-emoji-mart/picker';
@import 'ngx-toastr/toastr';
@import '@ctrl/ngx-emoji-mart/picker';
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import './assets/variable.scss';
// styles.scss

.button-cls {
    background-color: transparent;
}

html,
body {
    height: 100%;
    // cursor: pointer;
    position: relative;
    background-color: rgb(255, 255, 255) !important;
}

::ng-deep body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100% !important;
    background-color: rgb(255, 255, 255) !important;
}
.mat-calendar-period-button {
    pointer-events: none; /* Makes the button unclickable */
    opacity: 0.5; /* Optionally reduce opacity to indicate it's disabled */
  }
  

.mat-calendar-arrow.mat-calendar-invert {
    display: none !important;
}

.mat-form-field.mat-form-field-invalid .mat-mdc-form-field-outline {
    border-color: inherit !important;
    /* Keep the original border color */
}

.mat-form-field.mat-form-field-invalid .mat-mdc-form-field-outline-thick {
    border-color: inherit !important;
    border-width: 1px;
    /* Retain normal border thickness */
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: #EEEEEE !important;
}

// @media (max-width: 1440px) and (min-width: 1200px) {
//     body {
//         zoom: 68%;
//     }

//     body .mat-select{
//         zoom: 100% !important;
//     }

//     ::ng-deep .cdk-overlay-pane{
//         transform: translateX(0) translateY(-15px) !important;
//     }
// }





@font-face {
    font-family: 'Avenir';
    src: url('./assets/Fonts/Avenir.ttc');
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('./assets/Fonts/Inter/static/Inter-Bold.ttf');
}

@font-face {
    font-family: 'Inter-ExtraBold';
    src: url('./assets/Fonts/Inter/static/Inter-ExtraBold.ttf');
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url('./assets/Fonts/Inter/static/Inter-SemiBold.ttf');
}

@font-face {
    font-family: 'Inter-Medium';
    src: url('./assets/Fonts/Inter/static/Inter-Medium.ttf');
}

@font-face {
    font-family: 'Inter-Regular';
    src: url('./assets/Fonts/Inter/static/Inter-Regular.ttf');
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('./assets/Fonts/poppins/Poppins-Bold.ttf');
}


@font-face {
    font-family: 'Clash-Display';
    src: url('./assets/Fonts/ClashDisplay/ClashDisplay-Variable.ttf');
}

@font-face {
    font-family: 'Helvetica';
    src: url('./assets/Fonts/Helvetica/Helvetica.ttf');
}

@font-face {
    font-family: 'Helvetica-Neue';
    src: url('./assets/Fonts/HelveticaNeue/Helvetica-Neue.ttf');
}




button {
    border: 0px !important;
    box-shadow: none !important;
    outline: 0px !important;
}

button:focus {
    border: 0px !important;
    box-shadow: none !important;
    outline: 0px !important;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    width: 100%;
    border-radius: 8px !important;
    padding: 0px 15px 0px 15px !important;
    border: 0.5px solid #EEEEEE;
    background-color: $Colorwhite;
}

.textarea-container {
    textarea {
        height: 150px;
        width: 100%;
        padding: 15px 25px;
    }

}

.mat-form-field-appearance-fill .mat-form-field-infix {
    outline: 0px !important;
    height: 56px;
    padding: 0px 10px 0px 0px;
    display: flex;
    align-items: center;
    border-top: 0px;
}

.mat-form-field-underline {
    width: 0px !important;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    display: none;
}

// .mat-select-value-text{
//     position: absolute;
//     top:-5px;
// }

.mat-form-field {
    // @include fontStyleManager($fontfamilyMedium,$fontWeightMedium,$fontSize16);
    color: #1D1D1E;
}

.mat-select-arrow {
    color: #1D1D1E !important;
    background-image: url('./assets/images/arrow-down.svg');
    // transform: rotate(180deg) !important;
    background-size: 20px;
    width: 20px !important;
    height: 20px !important;
    background-repeat: no-repeat;
    border: 0px !important;
}

button:focus {
    box-shadow: none !important;
    border: 0px !important;
}

.cp {
    cursor: pointer;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: black;
}

.filter {
    top: 95px;
    bottom: 15px;
    position: absolute !important;

    .mat-dialog-container {
        @media (max-width:768px) {
            top: 20px;
            margin: 10px;
        }

    }

    @media (max-width:768px) {
        right: auto !important;
        max-width: 100% !important;
        // position: fixed !important;
    }

    .mat-tab-label {
        // background-color:#0093D6;
        border-radius: 40px;
        min-width: 126px;
        opacity: 1;
        border: 0px;
        color: black;
        background-color: transparent;
        border: 0.5px solid #BFD1E3;
        @include fontStyleManager($fontFamilyInterMedium, $fontWeightMedium, $fontSize16, $Color_0A0045)
    }

    // .mat-form-field-appearance-fill .mat-form-field-flex{
    //     padding: 13px 15px 16px 15px;


    // }
    .mat-form-field-wrapper {
        padding-bottom: 0px !important;
    }

    .mat-tab-nav-bar,
    .mat-tab-header {
        border: 0px;
    }
}

.interested {
    .mat-form-field-appearance-fill .mat-form-field-flex {
        width: 160px;
    }

    .mat-form-field-wrapper {
        padding: 0px !important;
    }
}

.payNow {
    button {
        width: 240px !important;
        max-width: 100%;
    }
}

.breadcrumb-item {
    @include fontStyleManager($fontFamilyInterSemiBold, $fontWeightSemiBold, $fontSize20, $Color_323B4B)
}

.breadcrumb-item+.breadcrumb-item::before {
    margin-top: 2px;
}

.mat-button-toggle-button {
    padding: 0px 5px !important;
    border: 1px solid #DBE9F5 !important;
    box-sizing: border-box;
    border-radius: 40px !important;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    font-weight: 600;

}

.mat-button-toggle-group {
    border: 0px;
    display: flex;
    flex-wrap: wrap;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
    border: 0px;
}

.mat-button-toggle {
    border-radius: 40px;
    margin: 8px;
    @include fontStyleManager($fontFamilyInterSemiBold, $fontWeightSemiBold, $fontSize14, $Color_0A0045)
}

.mat-button-toggle-checked {
    background-color: $Color_EE7402;
    color: $Colorwhite !important;
    border: 0px !important;
}

.mat-button-toggle-appearance-standard {
    border-radius: 40px;
}

// .mat-button-toggle-label-content{
//     @include fontStyleManager($fontFamilyInterSemiBold,$fontWeightSemiBold,$fontSize14,$Color_0A0045)
// }

.user-detail {
    .report {
        background-color: $Color_FF4746;
        border: 0px !important;
        color: $Colorwhite !important;
    }

    .cancel {
        border: 1px solid #DBE9F5 !important;
        background-color: transparent !important;
        color: #0A0045 !important;
    }
}

.yespost {
    h1 {
        font-size: 18px !important;
    }

    p {
        font-size: 14px !important;
    }

    .cancel {
        background-color: $Color_EE7402 !important;
    }
}

.owl-theme .owl-nav.disabled+.owl-dots {
    position: absolute;
    bottom: 0px;
    left: 50%;
}

.addNew {
    top: 95px !important;
    bottom: 10px !important;
    position: absolute !important;

}

.detail {
    position: absolute !important;
    right: 230px;
    top: 60px;
}

.products {
    h1 {
        font-size: 18px !important;
    }

    p {
        font-size: 14px !important;
    }
}

.mat-dialog-container {
    border-radius: 16px !important;
    border-top: 11px solid #EE7402 !important;
    position: relative !important;
}

.mat-select-panel {
    margin-top: 60px;
}

.mat-option.mat-active {
    background-color: $Colorwhite;

}

.mat-select-panel:not([class*=mat-elevation-z]) {
    border-radius: 8px;

}

.sales-filter {
    top: 175px;
    bottom: 10px;
    position: absolute !important;
    right: 20px;
    height: auto;
    max-height: auto;

    .mat-form-field-wrapper {
        padding: 0px !important;
    }

    .mat-dialog-container {
        @media (max-width:768px) {
            border-radius: 0px !important;
            padding: 0px !important;
            margin: 10px;

        }

    }

    @media (max-width:768px) {
        right: auto !important;
        max-width: 100% !important;
        // position: fixed !important;
        bottom: 0px;
        top: auto;
    }
}

.notificationdata {
    .form-check-input:checked {
        border-color: $Color_EE7402 ;
        background-color: $Color_EE7402 ;
    }

    .form-check-input:focus {
        box-shadow: none;

    }
}

.mat-form-field-wrapper {
    padding-bottom: 0px !important;
}

.dispatch {
    .cancel {
        background-color: $Color_EE7402 !important;
    }

    .modal-header {
        padding-top: 0px !important;
    }

    .modal-body {
        padding-bottom: 0px !important;

        img {
            display: none;
        }
    }

    h1 {
        padding-top: 0px;
    }
}

.credit {
    position: absolute !important;
    top: 160px;
    right: 35px;
    bottom: 10px;

    .mat-dialog-container {
        @media (max-width:768px) {
            //    border-radius: 0px !important;
            //    padding: 0px !important;
            margin: 10px;
        }

    }

    @media (max-width:768px) {
        right: auto !important;
        max-width: 100% !important;
        // position: fixed !important;
        bottom: 0px;
        top: auto;
    }
}

.resetPassword {
    button {
        width: 100%;
    }
}

// .otp-input:focus{
// border: 0px !important;
// border-bottom: 2px solid #23C99A !important;
// border-radius: 0px !important;
// }
.otp-input {
    border: 0px !important;
    border-bottom: 1px solid #23C99A !important;
    border-radius: 0px !important;
    outline: 0px !important;

}

.otp-input-error {
    border: 0px !important;
    border-bottom: 1px solid red !important;
    border-radius: 0px !important;
    outline: 0px !important;

}

.addNew {
    position: absolute !important;
    top: 10px;
}

.postPublish {
    .report {
        width: 200px;
    }
}

// .ShareTestimonial {
//     .mat-dialog-container {
//         // background-color: #EAF3FF    ;
//     }
// }

.mat-select-arrow-wrapper {
    display: inherit !important;
    vertical-align: inherit !important;
    height: 0px !important;
}

// [data-popper-placement="bottom-start"]>.drop{
//     transform: translate(50px, 50px);
// }
// [data-popper-placement="bottom-bottom"]>.drop{
//     transform: translate(50px, 50px);
// }

::-webkit-scrollbar {
    width: 0px !important;
    border-radius: 15px;
}

::-webkit-scrollbar-track {
    background: grey !important;
}

//   / Handle /
//   ::-webkit-scrollbar-thumb {
//     background: grey !important;
//   }

::-webkit-scrollbar-thumb:hover {
    background: grey !important;
}

// .business{
//     .mat-form-field-appearance-fill .mat-form-field-flex{
//         padding: 10px 15px 12px 0px !important;
//     }
// }#BFD1E3
.phonedetail {
    .mat-select-placeholder {
        @include fontStyleManager($fontFamilyInterMedium, $fontWeightMedium, $fontSize14, $color_5a678f);

        @media (max-width:768px) {
            font-size: 14px !important;
        }
    }

    .mat-select-value {
        @include fontStyleManager($fontFamilyInterMedium, $fontWeightMedium, $fontSize16, $Color_0A0045);

        @media (max-width:768px) {
            font-size: 14px !important;
        }
    }
}

.phonedetails {
    .mat-select-placeholder {
        @include fontStyleManager($fontFamilyInterMedium, $fontWeightMedium, $fontSize14, $Color_000000);

        @media (max-width:768px) {
            font-size: 14px !important;
        }
    }

    .mat-select-value {
        @include fontStyleManager($fontFamilyInterMedium, $fontWeightMedium, $fontSize16, $Color_0A0045);
    }
}

.datepicker {
    .mat-input-element {
        @include fontStyleManager($fontFamilyInterMedium, $fontWeightMedium, $fontSize16, $Color_0A0045);

    }
}

.invalid-feedback {
    font-size: 12px !important;
}

// .datepicker{
//     .mat-form-field-appearance-fill .mat-form-field-flex{
//         padding: 12px 15px 12px 15px !important;
//     }
// }
.cursor {
    cursor: pointer;
}

.form-control.is-invalid {
    // background-image: none !important;
    border-color: red;
}


.resetPassword {
    .btn {
        margin-top: 20px;
        width: 100% !important;
    }
}

.mat-button-toggle {
    margin: 0px !important;
    margin-right: 10px !important;
    margin-bottom: 20px !important;
}

.mat-select-placeholder {
    @include fontStyleManager($fontFamilyHelvetica, $fontWeightRegular, $fontSize16, $Color_000000);

    @media (max-width:768px) {
        font-size: 14px !important;
    }
}



.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    display: inline !important;
}


option:disabled {
    color: -internal-light-dark(graytext, red) !important;
}

.form-select.is-invalid:not([multiple]):not([size]) {
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0px) !important;
}

.location[_ngcontent-fuh-c185]::after {
    position: absolute;
    right: 22px !important;
    bottom: 28px !important;

}

.owl-item {
    img {
        border-radius: 10px;
    }

    video {
        border-radius: 10px;

    }
}

.productdetails {
    .owl-prev {
        top: 37%;
        left: 3px;
        position: absolute;
    }

    .owl-next {
        top: 37%;
        right: 3px;
        position: absolute;
    }
}

.owl-prev {
    top: 40%;
    left: 0px;
    position: absolute;
}

.owl-next {
    top: 40%;
    right: 0px;
    position: absolute;
}

.owl-theme .owl-nav [class*=owl-] {
    background-color: transparent !important;
}

.card-carousal {
    .owl-prev {
        top: 40%;
        left: 0px;
        position: absolute;
    }

    .owl-next {
        top: 40%;
        right: 0px;
        position: absolute;
    }

    img {
        border-radius: 0px;
    }

    video {
        border-radius: 0px;

    }
}

// .Joining{
//     .mat-form-field-appearance-fill .mat-form-field-flex{
//         padding: 9px 15px 12px 15px !important;

//     }
// }
.minimumFile {
    font-size: 12px;
    color: #dc3545;
}

.mat-icon-button .mat-button-wrapper {
    background-image: url('./assets/images/calendar-5.svg') !important;
    background-size: 25px 35px;
    width: 25px;
    height: 35px;
    background-repeat: no-repeat;

    svg {
        opacity: 0;
    }
}

input:focus {
    box-shadow: none;
    outline: none;

}

.mat-button-toggle-appearance-standard {
    // background-color: red !important;
    box-shadow: none !important;
}

.ngx-pagination .pagination-next a::after {
    display: none !important;
}

.ngx-pagination .pagination-next.disabled::after {
    display: none !important;
}

.ngx-pagination .pagination-previous a::before {
    display: none !important;
}

.ngx-pagination .pagination-previous.disabled::before {
    display: none !important;
}

.ngx-pagination a {
    margin-left: 5px;
}

.ngx-pagination {
    margin-left: 0 !important;
    margin-bottom: 1rem !important;
    line-height: 20px !important;
    padding: 7px 15px 7px !important;
    @include fontStyleManager($fontFamilyInterMedium, $fontWeightMedium, $fontSize18, $Color_5B6D8E);

    a {
        text-decoration: none !important;
    }
}

.owl-item {
    img {
        object-fit: cover;
    }
}

// .owl-stage{
//     width: 100% !important;
// }
.home {
    .owl-item {
        img {
            object-fit: inherit;
        }
    }
}

.mat-option-text {
    @include fontStyleManager($fontFamilyInterMedium, $fontWeightMedium, $fontSize16, $Color_0A0045);

    @media (max-width:768px) {
        font-size: 14px !important;
    }
}

.select-box-alert {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}

small {
    color: #dc3545;
    font-size: 12px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.owl-theme .owl-nav .disabled {
    opacity: 1 !important;
}

.upload_files {
    border: 1px solid #BFD1E3;
    border-radius: 10px;
    height: 90px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #768DA9;
}

.mat-step-header:hover {
    background-color: $Colorwhite !important;
}

.mat-step-header.cdk-program-focused {
    background-color: $Colorwhite;
}

.mat-step-header.cdk-mouse-focused {
    background-color: $Colorwhite;
}

.mat-step-header.cdk-focused {
    background-color: $Colorwhite;
}

::ng-deep .toast-bottom-right {
    position: fixed;
    bottom: 50px;
}

@media screen and (max-width: 767px) {
    .otp-input {
        width: 50px !important;
        height: 50px !important;
    }
}

@media screen and (max-width: 407px) {
    .otp-input {
        width: 40px !important;
        height: 40px !important;
    }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    @include fontStyleManager($fontFamilyInterMedium, $fontWeightMedium, $fontSize16, $Color_0A0045);

    @media (max-width:768px) {
        font-size: 14px !important;
    }
}

.ng-select .ng-clear-wrapper {
    display: none;
}

.ng-select span {
    font-family: 'Inter-Medium';
    font-weight: 500;
}

@media (max-width:768px) {
    .mat-horizontal-content-container {
        padding: 0px !important;
    }
}

.password-message {
    color: #768DA9 !important;
}

.uploadedImg {
    object-fit: cover;
}

.videoBackground {
    background: $Color_F2F8FF;
    width: 100%;
    max-height: 395px;
    border-radius: 16px;
}
.videothum{
    cursor: pointer;
}


::ng-deep .cdk-overlay-pane {
    transform: translateX(0) translateY(-15px) !important;
}

::ng-deep .cdk-overlay-container {
    position: absolute !important;
}

.shubfillternav {
    .btnblue {
        border: 1px solid #0000001A !important;
        background: $Color_F9FBFE;
        color: $Color_000000 !important;
        margin: 0px 4px;
        padding: 6px 15px;
        border-radius: $border16;
        font-weight: $fontWeightRegular !important;
        font-family: $fontFamilyHelvetica !important;
        font-size: $fontSize14 !important;
    }

    .active {
        background-color: $Color_002280;
        color: $Colorwhite !important;
    }
}

.imgborder {
    border-radius: 10px;
}
@media (max-width: 576px) {
    button.slick-prev {
        position: relative !important;
        left: 8px !important;
        border-radius: 20px !important;
        padding: 2px 7px !important;
        box-shadow: none !important;
        border: 0px !important;
        bottom: -8px !important;
        display: inline-block !important;
    }

    button.slick-next {
        bottom: -8px !important;
        border: 0px !important;
        position: relative !important;
        left: 10px !important;
        border-radius: 20px !important;
        padding: 2px 6px !important;
        box-shadow: none !important;
        display: inline-block !important;
    }
}


.custom-select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%; /* Adjust width as needed */
  }
  
  .custom-select {
    appearance: none; /* Remove default browser styles */
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px 40px 10px 10px; /* Add space for the arrow */
    width: 100%; /* Ensure the width is consistent */
    // font-size: 14px;
    // color: #333;
    cursor: pointer;
    outline: none;
    position: relative;
  }
  
  .custom-select:focus {
    border-color: #007bff; /* Highlight border on focus */
  }
  
  .custom-select-wrapper::after {
    content: "";
    font-size: 12px;
    color: #666;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #1D1D1E !important;
    background-image: url('assets/images/arrow-down.svg');
    background-size: 20px;
    width: 20px !important;
    height: 20px !important;
    background-repeat: no-repeat;
    border: 0px !important;
}
  
  .custom-select option {
    color: #333; /* Option text color */
  }

//   span.pricebold {
//     font-weight: 600;
// }
.searchborder{
    border: 1px solid #d3d3d3;
    margin-left: -8px !important;
    // padding: 8px !important;
    border-radius: 6px;
    width: 100%;
    }